import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, respondTo, spacing } from '@vfuk/core-mixins'

import { NotificationWrapperProps, StyledStateNotificationProps } from './StateNotification.style.types'

export const StateNotification = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
  `,
)

export const IconWrapper = styled.div(
  (props: StyledStateNotificationProps): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [4])};
    background: ${props.theme.color[props.state].default};
  `,
)

export const ContentWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [4])};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
  `,
)

export const NotificationWrapper = styled.div(
  (props: NotificationWrapperProps): FlattenSimpleInterpolation => css`
    width: 100%;
    display: flex;

    ${props.hasButton &&
    `
    flex-direction: column;
  `}

    ${props.hasButton &&
    respondTo.md(css`
      flex-direction: row;
    `)}
  `,
)

export const CloseButton = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    ${advancedSpacing('padding', [4, 4, 0, 0])};
    align-self: flex-start;
  `,
)

export const ButtonWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: flex-start;
    ${spacing('padding', 4)}

    ${respondTo.md(css`
      flex-direction: row;
    `)}
  `,
)
