import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, responsiveSizing, spacing, typography } from '@vfuk/core-mixins'

import { StyledNumberBadgeProps } from './NumberBadge.style.types'

import localThemes from '../themes/NumberBadge.theme'

export const NumberBadge = styled.span(
  (props: StyledNumberBadgeProps): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [0, 2])};
    ${responsiveSizing('height', 4)};
    background-color: ${localThemes(props.theme).appearance[props.appearance!].background};
    border-radius: ${props.theme.border.radius[3]};
    text-align: center;
    ${typography.paragraph(1, false)}
    font-weight: ${props.theme.fontWeight[3]};
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ${props.isSmallNumber &&
    css`
      ${spacing('padding', 0)};
      aspect-ratio: 1 / 1;
    `}
  `,
)

type NumberProps = Pick<StyledNumberBadgeProps, 'theme' | 'appearance'>
export const Number = styled.span(
  (props: NumberProps): FlattenSimpleInterpolation => css`
    color: ${localThemes(props.theme).appearance[props.appearance!].color};
    ${responsiveSizing('line-height', 4)};
  `,
)
