import React, { PureComponent, ReactNode, RefObject } from 'react'
import { withTheme } from 'styled-components'

import * as Styled from './styles/Interaction.style'

import { InteractionProps } from './Interaction.types'

/**
 * Interaction component
 * Notes:
 * - onClick for RouterLink and Link are for analytics purpose only!
 */
export class Interaction extends PureComponent<InteractionProps> {
  public static defaultProps: Partial<InteractionProps> = {
    type: 'button',
    target: '_self',
  }

  public render(): ReactNode {
    const interactionHandlers = !this.props.disabled && {
      onClick: this.props.onClick,
      onKeyDown: this.props.onKeyDown,
      onMouseDown: this.props.onMouseDown,
      onMouseUp: this.props.onMouseUp,
      onTouchStart: this.props.onTouchStart,
      onTouchEnd: this.props.onTouchEnd,
    }
    const isInteractive = !!Object.values(interactionHandlers).find((handlerVal) => handlerVal)

    const Router = this.props.theme?.customRouter
    const linkRouterProps = {
      ref: this.props.domRef as RefObject<HTMLAnchorElement>,
      isFullSize: this.props.fullSize,
      href: this.props.href,
      'aria-label': this.props.label,
      target: this.props.target,
      ...interactionHandlers,
      ...this.props.dataAttributes,
      className: this.props.className,
      id: this.props.id,
      role: this.props.role,
      rel: this.props.rel,
    }
    return (
      <Choose>
        <When condition={this.props.theme?.customRouter && this.props.customRouterProps}>
          <Styled.Interaction as={Router as unknown as React.ElementType} {...linkRouterProps} {...this.props.customRouterProps}>
            {this.props.children}
          </Styled.Interaction>
        </When>
        <When condition={this.props.href}>
          <Styled.Interaction as={'a' as React.ElementType} {...linkRouterProps}>
            {this.props.children}
          </Styled.Interaction>
        </When>
        <When condition={isInteractive || this.props.disabled}>
          <Styled.Button
            ref={this.props.domRef as RefObject<HTMLButtonElement>}
            isFullSize={this.props.fullSize}
            type={this.props.type}
            aria-label={this.props.label}
            aria-disabled={this.props.disabled}
            {...interactionHandlers}
            {...this.props.dataAttributes}
            className={this.props.className}
            id={this.props.id}
            role={this.props.role}
          >
            {this.props.children}
          </Styled.Button>
        </When>
        <Otherwise>
          <Styled.BlankInteraction
            ref={this.props.domRef as RefObject<HTMLDivElement>}
            isFullSize={this.props.fullSize}
            {...this.props.dataAttributes}
            className={this.props.className}
            id={this.props.id}
          >
            {this.props.children}
          </Styled.BlankInteraction>
        </Otherwise>
      </Choose>
    )
  }
}

export default withTheme(Interaction)
