import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { NumberBadgeTheme } from './NumberBadge.theme.types'

const numberBadgeLocal = (theme: Theme): NumberBadgeTheme => {
  return {
    appearance: {
      primary: {
        background: theme.color.primary1.default,
        color: theme.color.monochrome1.default,
      },
      secondary: {
        background: theme.color.monochrome1.default,
        color: theme.color.primary1.default,
      },
    },
  }
}

export default numberBadgeLocal
