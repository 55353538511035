import React, { FC, ReactElement } from 'react'

import IconButton from '@vfuk/core-icon-button'

import { CarouselArrowProps } from './CarouselArrow.types'

import * as Styled from './styles/CarouselArrow.style'

const CarouselArrow: FC<CarouselArrowProps> = ({
  position = 'overlap',
  pagerPosition = 'outside',
  direction,
  inverse = false,
  shape = 'circle',
  onClick,
}: CarouselArrowProps): ReactElement => {
  return (
    <Styled.CarouselArrow position={position} pagerPosition={pagerPosition} direction={direction}>
      <IconButton
        icon={{ name: direction === 'next' ? 'chevron-right' : 'chevron-left' }}
        size={2}
        appearance='alt1'
        inverse={inverse}
        shape={shape}
        onClick={onClick}
        srText={`Move to ${direction} slide`}
      />
    </Styled.CarouselArrow>
  )
}

export default CarouselArrow
