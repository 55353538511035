import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Theme } from '@vfuk/core-themes/dist/themes.types'
import createMixin from '@vfuk/core-mixins/dist/utils/createMixin'
import { respondTo } from '@vfuk/core-mixins'
import { Size, RequiredSizes, AllSizes } from '@vfuk/core-icon-generator/dist/IconGenerator.types'

import { IconSize } from '../../Icon.types'

function getIconSizeFromThemeAndGroup(theme: Theme, group: string, size: IconSize): Size {
  const iconProperties = theme.getIconProperties(group)
  if (!size) return iconProperties.size[iconProperties.defaultSize]
  return iconProperties.size[size as unknown as keyof (RequiredSizes | AllSizes)] || iconProperties.size[iconProperties.defaultSize]
}

function checkIconWidthOverride(theme: Theme, group: string): string | undefined {
  const iconProperties = theme.getIconProperties(group)
  if (iconProperties.widthOverride) return iconProperties.widthOverride
  return undefined
}

export const getIconSize = createMixin(
  (theme: Theme, isResponsive: boolean, iconSize: Size, iconWidthOverride: string | undefined): FlattenSimpleInterpolation => {
    if (!isResponsive) {
      return css`
        width: ${iconWidthOverride || `${iconSize.xl}px`};
        height: ${iconSize.xl}px;
      `
    }

    return css`
      width: ${iconWidthOverride || `${iconSize.sm}px`};
      height: ${iconSize.sm}px;

      ${respondTo.md(css`
        width: ${iconWidthOverride || `${iconSize.md}px`};
        height: ${iconSize.md}px;
      `)}

      ${respondTo.lg(css`
        width: ${iconWidthOverride || `${iconSize.lg}px`};
        height: ${iconSize.lg}px;
      `)}

    ${respondTo.xl(css`
        width: ${iconWidthOverride || `${iconSize.xl}px`};
        height: ${iconSize.xl}px;
      `)}
    `
  },
)

export default createMixin((theme: Theme, size: IconSize, isResponsive: boolean, group: string): FlattenSimpleInterpolation => {
  const iconSize = getIconSizeFromThemeAndGroup(theme, group, size)
  const iconWidthOverride = checkIconWidthOverride(theme, group)

  return css`
    ${getIconSize(isResponsive, iconSize, iconWidthOverride)}
  `
})
