import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { IconButton } from '@vfuk/core-icon-button/dist/styles/IconButton.style'
import { elevation, spacing } from '@vfuk/core-mixins'

import { StyledCarouselArrowProps } from './CarouselArrow.style.types'

export const CarouselArrow = styled.div<StyledCarouselArrowProps>(
  (props: StyledCarouselArrowProps): FlattenSimpleInterpolation => css`
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    ${elevation(true)};

    ${IconButton} {
      height: 36px;
      width: 36px;
    }

    ${props.position === 'outside' &&
    css`
      ${props.direction === 'next' &&
      css`
        left: 100%;
        ${spacing('margin-left', 2)}
      `}

      ${props.direction === 'previous' &&
      css`
        right: 100%;
        ${spacing('margin-right', 2)}
      `}
    `}

    ${props.position === 'overlap' &&
    css`
      ${props.direction === 'next' &&
      css`
        left: 100%;
        transform: translate(-100%, -50%);
      `}

      ${props.direction === 'previous' &&
      css`
        right: 100%;
        transform: translate(100%, -50%);
      `}
    `}

  ${props.pagerPosition === 'outside' &&
    css`
      margin-top: -14px;
    `}

  &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
    }
  `,
)
