import React, { PureComponent, ReactNode } from 'react'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/InteractiveIcon.style'

import { InteractiveIconProps } from './InteractiveIcon.types'

export default class InteractiveIcon extends PureComponent<InteractiveIconProps> {
  public static defaultProps: Partial<InteractiveIconProps> = {
    appearance: 'primary',
    size: 4,
    htmlAttributes: {},
    isResponsive: true,
    inverse: false,
    group: 'system',
  }

  public render(): ReactNode {
    return (
      <Styled.InteractiveIcon
        label={this.props.srText}
        target={this.props.htmlAttributes!.target}
        type={this.props.htmlAttributes!.type}
        dataAttributes={this.props.htmlAttributes!.dataAttributes}
        disabled={this.props.state === 'disabled'}
        onClick={this.props.onClick}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
        href={this.props.href}
        id={this.props.id}
        customRouterProps={this.props.customRouterProps}
      >
        <Icon
          name={this.props.name}
          appearance={this.props.appearance}
          inverse={this.props.inverse}
          size={this.props.size}
          isResponsive={this.props.isResponsive!}
          group={this.props.group!}
          numberBadge={this.props.numberBadge}
        />
      </Styled.InteractiveIcon>
    )
  }
}
