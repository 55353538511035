/* eslint-disable max-len */
import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { ButtonTheme, StateProps } from './Button.theme.types'

const buttonLocal = (theme: Theme): ButtonTheme => {
  const stateSuccess: StateProps = {
    color: theme.color.monochrome1.default,
    backgroundColor: theme.color.success.default,
    iconAppearance: 'primary',
    inverseIcon: true,
    borderColor: 'transparent',
    hover: {
      color: theme.color.monochrome1.default,
      backgroundColor: theme.color.success.hover,
      borderColor: 'transparent',
      iconAppearance: 'primary',
    },
    pressed: {
      color: theme.color.monochrome1.default,
      backgroundColor: theme.color.success.pressed,
      borderColor: 'transparent',
    },
  }

  const state = {
    success: stateSuccess,
    selected: stateSuccess,
  }

  return {
    appearance: {
      primary: {
        color: theme.color.monochrome1.default,
        backgroundColor: theme.color.primary1.default,
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.pressed,
          borderColor: 'transparent',
        },
        iconAppearance: 'primary',
        inverseIcon: true,
        borderColor: 'transparent',
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.hover,
          borderColor: 'transparent',
          iconAppearance: 'secondary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome1.disabled,
            backgroundColor: theme.color.primary1.disabled,
            iconAppearance: 'primary',
            inverseIcon: true,
            borderColor: 'transparent',
            hover: {
              color: theme.color.monochrome1.disabled,
              backgroundColor: theme.color.primary1.disabled,
              borderColor: 'transparent',
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: theme.inverseColor.primary1.default,
          pressed: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.pressed,
            borderColor: 'transparent',
          },
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: 'transparent',
          hover: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.hover,
            borderColor: 'transparent',
            iconAppearance: 'secondary',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.disabled,
              backgroundColor: theme.inverseColor.primary1.disabled,
              iconAppearance: 'primary',
              inverseIcon: true,
              borderColor: 'transparent',
              hover: {
                color: theme.inverseColor.monochrome6.disabled,
                backgroundColor: theme.inverseColor.primary1.disabled,
                borderColor: 'transparent',
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      secondary: {
        color: theme.color.monochrome1.default,
        backgroundColor: theme.color.monochrome6.default,
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.pressed,
          borderColor: 'transparent',
        },
        iconAppearance: 'primary',
        inverseIcon: true,
        borderColor: 'transparent',
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.hover,
          borderColor: 'transparent',
          iconAppearance: 'secondary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome1.disabled,
            backgroundColor: theme.color.monochrome6.disabled,
            iconAppearance: 'primary',
            inverseIcon: true,
            borderColor: 'transparent',
            hover: {
              color: theme.color.monochrome1.disabled,
              backgroundColor: theme.color.monochrome6.disabled,
              borderColor: 'transparent',
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome1.default,
          backgroundColor: theme.inverseColor.monochrome6.default,
          pressed: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.pressed,
            borderColor: 'transparent',
          },
          iconAppearance: 'primary',
          inverseIcon: false,
          borderColor: 'transparent',
          hover: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.hover,
            borderColor: 'transparent',
            iconAppearance: 'primary',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome1.disabled,
              backgroundColor: theme.inverseColor.monochrome6.disabled,
              iconAppearance: 'primary',
              inverseIcon: false,
              borderColor: 'transparent',
              hover: {
                color: theme.inverseColor.monochrome1.disabled,
                backgroundColor: theme.inverseColor.monochrome6.disabled,
                borderColor: 'transparent',
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      alt1: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.pressed,
          borderColor: 'transparent',
        },
        iconAppearance: 'primary',
        inverseIcon: false,
        borderColor: theme.color.monochrome6.default,
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.hover,
          borderColor: 'transparent',
          iconAppearance: 'primary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome6.disabled,
            backgroundColor: 'transparent',
            iconAppearance: 'primary',
            inverseIcon: false,
            borderColor: theme.color.monochrome6.disabled,
            hover: {
              color: theme.color.monochrome6.disabled,
              backgroundColor: 'transparent',
              borderColor: theme.color.monochrome6.disabled,
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          pressed: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.pressed,
            borderColor: 'transparent',
          },
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: theme.color.monochrome1.default,
          hover: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.hover,
            borderColor: 'transparent',
            iconAppearance: 'alt1',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.disabled,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
              inverseIcon: true,
              borderColor: theme.color.monochrome1.disabled,
              hover: {
                color: theme.inverseColor.monochrome6.disabled,
                backgroundColor: 'transparent',
                borderColor: theme.color.monochrome1.disabled,
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      alt2: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.pressed,
          borderColor: 'transparent',
        },
        iconAppearance: 'primary',
        inverseIcon: false,
        borderColor: theme.color.primary1.default,
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.hover,
          borderColor: 'transparent',
          iconAppearance: 'alt1',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome6.disabled,
            backgroundColor: 'transparent',
            iconAppearance: 'primary',
            inverseIcon: false,
            borderColor: theme.color.primary1.disabled,
            hover: {
              color: theme.color.monochrome6.disabled,
              backgroundColor: 'transparent',
              borderColor: theme.color.primary1.disabled,
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          pressed: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.pressed,
            borderColor: 'transparent',
          },
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: theme.inverseColor.primary1.default,
          hover: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.hover,
            borderColor: 'transparent',
            iconAppearance: 'alt1',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.disabled,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
              inverseIcon: true,
              borderColor: theme.inverseColor.primary1.disabled,
              hover: {
                color: theme.inverseColor.monochrome6.disabled,
                backgroundColor: 'transparent',
                borderColor: theme.inverseColor.primary1.disabled,
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
    },
    inverse: false,
    borderRadius: theme.border.radius[2],
    borderWidth: theme.border.width[2],
    focus: theme.color.focus.default,
  }
}

export default buttonLocal
