import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Container } from '@vfuk/core-container/dist/styles/Container.style'
import { respondTo, spacing } from '@vfuk/core-mixins'

import { StyledModalRendererProps } from './ModalRenderer.style.types'

import localThemes from '../themes/ModalRenderer.theme'

type ModalRendererTheme = Pick<StyledModalRendererProps, 'theme'>

type ModalRendererProps = Pick<StyledModalRendererProps, 'theme' | 'zIndex'>
export const ModalRenderer = styled.div((props: ModalRendererProps): FlattenSimpleInterpolation => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    ${spacing('padding', 3)}
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${props.zIndex};
  `
})

export const ModalWrapper = styled.div(
  (props: ModalRendererTheme) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${respondTo.md(css`
      top: ${props.theme.spacing.responsive[5].md}px;
      left: ${props.theme.spacing.responsive[5].md}px;
      right: ${props.theme.spacing.responsive[5].md}px;
      bottom: ${props.theme.spacing.responsive[5].md}px;
      width: auto;
    `)}
  `,
)

type ContainerWrapperProps = Pick<StyledModalRendererProps, 'theme' | 'size' | 'fullScreenOnSM'>
export const ContainerWrapper = styled.div<ContainerWrapperProps>((props) => {
  const theme = localThemes(props.theme)

  return css`
    pointer-events: auto;
    width: ${theme.size[props.size!].sm};
    max-height: 100%;

    ${props.fullScreenOnSM &&
    css`
      height: 100%;
    `}

    ${respondTo.md(css`
      max-width: ${theme.size[props.size!].md};
      height: auto;
    `)}

    ${respondTo.lg(css`
      max-width: ${theme.size[props.size!].lg};
      height: auto;
    `)}

    ${respondTo.xl(css`
      max-width: ${theme.size[props.size!].xl};
      height: auto;
    `)}

    & > ${Container} {
      width: 100%;
      max-height: 100%;

      ${props.fullScreenOnSM &&
      css`
        border-radius: 0;
      `}

      ${respondTo.md(css`
        border-radius: ${props.theme.border.radius[2]};
      `)}

      & > div {
        max-height: 100%;
        overflow: auto;
      }
    }
  `
})

export const ModalContent = styled.div(
  () => css`
    max-height: 100%;
    overflow: auto;
    ${spacing('padding', 7)}
  `,
)
