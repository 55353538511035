import { Theme } from '@vfuk/core-themes/dist/themes.types'

import WS10 from './IconButton.ws10.theme'
import VOXI from './IconButton.voxi.theme'

import { IconButtonTheme } from './IconButton.theme.types'

const getLocalTheme = (theme: Theme): IconButtonTheme => {
  switch (theme.name) {
    case 'VOXI':
      return VOXI(theme)
    case 'WS2':
    case 'WS10':
    default:
      return WS10(theme)
  }
}

export default getLocalTheme
