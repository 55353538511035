import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledLoadingSpinnerProps } from './LoadingSpinner.style.types'

import localTheme from '../themes/LoadingSpinner.theme'

export const LoadingSpinner = styled.span<StyledLoadingSpinnerProps>((props: StyledLoadingSpinnerProps): FlattenSimpleInterpolation => {
  const theme = localTheme(props.theme)
  const strokeColor = props.inverse ? theme.appearance[props.appearance!]?.inverse.color : theme.appearance[props.appearance!]?.color

  return css`
    ${theme.animationStyle(theme.size[props.size!].width, theme.size[props.size!].height, strokeColor)}
  `
})
