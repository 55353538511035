import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { FlyoutRendererTheme } from './FlyoutRenderer.theme.types'

const flyoutRendererLocal = (theme: Theme): FlyoutRendererTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1.default,
        textColor: theme.color.monochrome6.default,
        inverseIcon: false,
      },
      secondary: {
        backgroundColor: theme.color.monochrome6.default,
        textColor: theme.color.monochrome1.default,
        inverseIcon: true,
      },
    },
  }
}

export default flyoutRendererLocal
