/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { Icon } from '@vfuk/core-icon/dist/styles/Icon.style'
import { LoadingSpinner } from '@vfuk/core-loading-spinner/dist/styles/LoadingSpinner.style'
import { iconAppearance, opacity, respondTo } from '@vfuk/core-mixins'

import { StyledIconButtonProps } from './IconButton.style.types'

import localThemes from '../themes/IconButton.theme'

export const IconButton = styled(Interaction)<StyledIconButtonProps>((props: StyledIconButtonProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    height: ${localTheme.size[props.size!].sm.buttonSize}px;
    width: ${localTheme.size[props.size!].sm.buttonSize}px;
    border-radius: ${props.theme.border.radius[2]};
    font-weight: ${props.theme.fontWeight[2]};
    background: ${localTheme.appearance[props.appearance!].backgroundColor};
    border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].border};

    ${Icon} {
      * {
        ${iconAppearance(localTheme.appearance[props.appearance!].iconColor)};
      }
    }

    &:active {
      background: ${localTheme.appearance[props.appearance!].pressed.backgroundColor};
      border-color: ${localTheme.appearance[props.appearance!].pressed.border};

      ${Icon} {
        * {
          ${iconAppearance(localTheme.appearance[props.appearance!].pressed.iconColor)};
        }
      }
    }

    &:hover {
      background: ${localTheme.appearance[props.appearance!].hover.backgroundColor};
      border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].hover.border};

      ${Icon} {
        * {
          ${iconAppearance(localTheme.appearance[props.appearance!].hover.iconColor)};
        }
      }

      ${LoadingSpinner} {
        svg {
          & > * {
            stroke: ${localTheme.appearance[props.appearance!].hover.iconColor};
          }
        }
      }
    }

    ${respondTo.md(css`
      height: ${localTheme.size[props.size!].md.buttonSize}px;
      width: ${localTheme.size[props.size!].md.buttonSize}px;
    `)}

    ${props.inverse &&
    css`
      background: ${localTheme.appearance[props.appearance!].inverse.backgroundColor};
      border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].inverse.border};

      &:hover {
        background: ${localTheme.appearance[props.appearance!].inverse.hover.backgroundColor};
        border: ${props.theme.border.width[2]} ${props.theme.border.style[1]}
          ${localTheme.appearance[props.appearance!].inverse.hover.border};

        ${Icon} {
          * {
            ${iconAppearance(localTheme.appearance[props.appearance!].inverse.hover.iconColor)};
          }
        }

        ${LoadingSpinner} {
          svg {
            & > * {
              stroke: ${localTheme.appearance[props.appearance!].inverse.hover.iconColor};
            }
          }
        }
      }

      ${Icon} {
        * {
          ${iconAppearance(localTheme.appearance[props.appearance!].inverse.iconColor)};
        }
      }

      &:active {
        background: ${localTheme.appearance[props.appearance!].inverse.pressed.backgroundColor};
        border-color: ${localTheme.appearance[props.appearance!].inverse.pressed.border};

        ${Icon} {
          * {
            ${iconAppearance(localTheme.appearance[props.appearance!].inverse.pressed.iconColor)};
          }
        }
      }
    `}

    ${props.shape === 'circle' &&
    css`
      border-radius: 50%;
    `}

    ${props.state &&
    css`
      ${props.state === 'disabled' &&
      css`
        ${opacity(true)};
        cursor: not-allowed;
      `}

      ${props.state !== 'disabled' &&
      css`
        background: ${localTheme.state[props.state!].backgroundColor};

        &:hover {
          background: ${localTheme.state[props.state!].hover.backgroundColor};

          ${Icon} {
            * {
              ${iconAppearance(localTheme.state[props.state!]!.iconColor)};
            }
          }
        }
      `}
    `}
  `
})

export const LoadingIcon = styled.span((): FlattenSimpleInterpolation => {
  return css`
    position: absolute;
    top: auto;
    bottom: auto;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
  `
})
