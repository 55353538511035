import { Theme } from '@vfuk/core-themes/dist/themes.types'

import WS10 from './LoadingSpinner.ws10.theme'
import VOXI from './LoadingSpinner.voxi.theme'

import { LoadingSpinnerTheme } from './LoadingSpinner.theme.types'

const getLocalThemes = (theme: Theme): LoadingSpinnerTheme => {
  if (theme.name === 'WS2' || theme.name === 'WS10') {
    return WS10(theme)
  }
  return VOXI(theme)
}
export default getLocalThemes
