import React, { PureComponent, ReactNode } from 'react'

import Container from '@vfuk/core-container'
import Heading from '@vfuk/core-heading'
import Button from '@vfuk/core-button'
import Icon from '@vfuk/core-icon'

import { IconProps } from '@vfuk/core-icon/dist/Icon.types'

import InteractiveIcon from '@vfuk/core-interactive-icon'

import { StateNotificationProps } from './StateNotification.types'

import * as Styled from './styles/StateNotification.style'

export default class StateNotification extends PureComponent<StateNotificationProps> {
  public static defaultProps: Partial<StateNotificationProps> = {
    srAlert: true,
  }

  private static getIconName(state: StateNotificationProps['state']): IconProps['name'] {
    return `${state}-inverse` as IconProps['name']
  }

  public render(): ReactNode {
    return (
      <Container state={this.props.state} allowOverflow={false} paddingLevel={0}>
        <Styled.StateNotification aria-live={this.props.srAlert ? 'assertive' : 'off'} aria-atomic={this.props.srAlert}>
          <Styled.IconWrapper state={this.props.state}>
            <Icon name={StateNotification.getIconName(this.props.state)} group='state' size={3} isResponsive />
          </Styled.IconWrapper>
          <Styled.NotificationWrapper hasButton={!!this.props.button}>
            <Styled.ContentWrapper>
              <If condition={this.props.heading?.text}>
                <Heading level={this.props.heading?.level || 2} text={this.props.heading!.text} size={1} weight={3} />
              </If>
              <If condition={this.props.children}>{this.props.children}</If>
            </Styled.ContentWrapper>
            <If condition={this.props.button}>
              <Styled.ButtonWrapper>
                <Button appearance='alt1' {...this.props.button!} />
              </Styled.ButtonWrapper>
            </If>
            <If condition={this.props.closeButton}>
              <Styled.CloseButton>
                <InteractiveIcon srText={this.props.closeButton!.srText} name='close' size={2} onClick={this.props.closeButton!.onClick} />
              </Styled.CloseButton>
            </If>
          </Styled.NotificationWrapper>
        </Styled.StateNotification>
      </Container>
    )
  }
}
