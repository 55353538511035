/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import ListGroup from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'
import { advancedSpacing, respondTo, responsivePosition, spacing } from '@vfuk/core-mixins'

import { StyledFunctionalCarouselProps, StyledCarouselPagerProps } from './FunctionalCarousel.style.types'

import localThemes from '../themes/FunctionalCarousel.theme'

export const CarouselWrapper = styled.div<Pick<StyledFunctionalCarouselProps, 'theme' | 'allowOverflow'>>(
  (props: Pick<StyledFunctionalCarouselProps, 'theme' | 'allowOverflow'>): FlattenSimpleInterpolation => css`
    width: 100%;

    ${props.allowOverflow &&
    css`
      padding: 0 ${props.theme.spacing.responsive[8].sm}px;

      ${respondTo.md(css`
        padding: 0 ${props.theme.spacing.responsive[8].md}px;
      `)}

      ${respondTo.lg(css`
        padding: 0 ${props.theme.spacing.responsive[8].lg}px;
      `)}

    ${respondTo.xl(css`
        padding: 0 ${props.theme.spacing.responsive[8].xl}px;
      `)}
    `}
  `,
)

export const Carousel = styled.div<StyledFunctionalCarouselProps>((props: StyledFunctionalCarouselProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    position: relative;
    ${advancedSpacing('margin', [0, -2])}

    .slick-slide {
      transition: 0.4s opacity ease;
      height: auto;
      display: grid;

      & > * {
        ${advancedSpacing('margin', [0, 2])}

        ${props.elevatedCards &&
        css`
          margin: ${localTheme.cardBoxShadowOffset}px ${props.theme.spacing.responsive[2].sm}px;

          ${respondTo.md(css`
            margin: ${localTheme.cardBoxShadowOffset}px ${props.theme.spacing.responsive[2].md}px;
          `)}

          ${respondTo.lg(css`
            margin: ${localTheme.cardBoxShadowOffset}px ${props.theme.spacing.responsive[2].lg}px;
          `)}

          ${respondTo.xl(css`
            margin: ${localTheme.cardBoxShadowOffset}px ${props.theme.spacing.responsive[2].xl}px;
          `)}
        `}
      }
    }

    .slick-track {
      display: flex;
    }

    ${!props.transitioning &&
    css`
      .slick-slide:not(.slick-active) {
        visibility: hidden;
      }

      ${props.allowOverflow &&
      css`
        .slick-active + .slick-slide:not(.slick-active),
        .slick-active + .slick-cloned:not(.slick-active),
        .slick-slide.overflow-slide:not(.slick-active) {
          visibility: visible;
          opacity: 0.6;
        }
      `}
    `}

    ${props.allowOverflow &&
    css`
      .slick-list {
        overflow: visible;
      }
    `}
  `
})

export const CarouselControls = styled.div<Pick<StyledFunctionalCarouselProps, 'theme' | 'pagerPosition'>>(
  (props: Pick<StyledFunctionalCarouselProps, 'theme' | 'pagerPosition'>): FlattenSimpleInterpolation => css`
    position: absolute;
    ${responsivePosition('bottom', 3)}
    ${responsivePosition('right', 5)}
  z-index: 10;

    ${(props.pagerPosition === 'outside' || !props.pagerPosition) &&
    css`
      bottom: ${props.theme.spacing.responsive[2].sm + 24}px;

      ${respondTo.md(css`
        bottom: ${props.theme.spacing.responsive[2].md + 24}px;
      `)}

      ${respondTo.lg(css`
        bottom: ${props.theme.spacing.responsive[2].lg + 24}px;
      `)}

    ${respondTo.xl(css`
        bottom: ${props.theme.spacing.responsive[2].xl + 24}px;
      `)}
    `}
  `,
)

export const CarouselDotPagerList = styled.ul<StyledCarouselPagerProps>(
  (props: StyledCarouselPagerProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    ${spacing('gap', 1)}
    ${advancedSpacing('margin', [3, 0])}

  ${props.position === 'inside' &&
    css`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background: ${props.theme.color.monochrome1.default};
      border-radius: 6px;
      ${advancedSpacing('margin', [0, 0, 3])}
    `}
  `,
)

export const CarouselLoaderPager = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('padding-top', 4)}
  `,
)

export const CarouselLoaderPagerList = styled(ListGroup)(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)

export const CarouselLoaderPagerArrow = styled(ListItem)(
  (props: StyledCarouselPagerProps): FlattenSimpleInterpolation => css`
    display: block;
    color: ${props.theme.color.secondary7.default};
    ${advancedSpacing('margin', [0, 4])}
    font-size: ${props.theme.typography.paragraph[2].fontDesktop};

    & span {
      vertical-align: bottom;
    }
  `,
)
