import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Heading } from '@vfuk/core-heading/dist/styles/Heading.style'
import { Icon } from '@vfuk/core-icon/dist/styles/Icon.style'
import { respondTo, spacing } from '@vfuk/core-mixins'

import { StyledCardBodyProps } from './CardBody.style.types'

export const CardBody = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ${spacing('padding', 3)}
  `,
)

type ContentWrapperHeaderProps = Pick<StyledCardBodyProps, 'hasChevron'>
export const ContentWrapperHeader = styled.div(
  (props: ContentWrapperHeaderProps): FlattenSimpleInterpolation => css`
    ${spacing('margin-bottom', 3)};

    ${props.hasChevron &&
    css`
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row-reverse;
    `}

    ${Heading} {
      ${props.hasChevron &&
      css`
        ${spacing('margin-right', 3)};
      `}
    }

    ${Icon} {
      margin-left: auto;
    }
  `,
)

export const ContentWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    flex-grow: 1;
    ${spacing('margin-bottom', 5)};

    &:last-child {
      margin-bottom: 0;
    }
  `,
)

type ButtonWrapperProps = Pick<StyledCardBodyProps, 'centerButtons'>
export const ButtonWrapper = styled.div(
  (props: ButtonWrapperProps): FlattenSimpleInterpolation => css`
    ${spacing('margin-bottom', 3)};
    ${spacing('gap', 3)};
    display: flex;
    flex-direction: column;

    ${respondTo.md(css`
      flex-direction: row;
      justify-content: ${props.centerButtons ? 'center' : 'left'};
    `)}
  `,
)
