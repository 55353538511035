import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/Link.style'

import { LinkProps } from './Link.types'

const Link: FC<LinkProps> = ({
  showUnderline = false,
  href,
  htmlAttributes = {},
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  inverse,
  srText,
  text,
  children,
  size = 2,
  id,
  customRouterProps,
}: LinkProps): ReactElement => {
  return (
    <Styled.Link
      href={href}
      target={htmlAttributes!.target}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      showUnderline={showUnderline!}
      inverse={inverse}
      label={srText}
      rel={htmlAttributes!.rel}
      dataAttributes={htmlAttributes!.dataAttributes}
      size={size}
      id={id}
      customRouterProps={customRouterProps}
    >
      {text || children}
    </Styled.Link>
  )
}

export default Link
