import React, { PureComponent, ReactNode } from 'react'
import { BreakpointsTypeKeys } from '@vfuk/core-themes/dist/constants/breakpoints.types'

import { withBreakpoints } from './helpers/withBreakpoints'

import { MatchMediaProps } from './MatchMedia.types'

export class MatchMedia extends PureComponent<MatchMediaProps> {
  get fullBreakpoint(): BreakpointsTypeKeys {
    const andAbove = this.props.andAbove ? 'AndAbove' : ''
    const andBelow = this.props.andBelow ? 'AndBelow' : ''
    return `${this.props.breakpoint}${andAbove || andBelow || ''}` as BreakpointsTypeKeys
  }

  public render(): ReactNode {
    if (!this.props.breakpoints![this.fullBreakpoint]) return null
    return <>{this.props.children}</>
  }
}

export default withBreakpoints(MatchMedia)
