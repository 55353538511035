import React, { PureComponent, ReactNode } from 'react'

import Icon from '@vfuk/core-icon'

import isNumber from '@vfuk/core-helpers/dist/isNumber'

import * as Styled from './styles/TextInput.style'

import { TextInputProps, TypeSpecificProps } from './TextInput.types'

export default class TextInput extends PureComponent<TextInputProps> {
  public static defaultProps: Partial<TextInputProps> = {
    autoComplete: 'on',
    type: 'text',
  }

  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (this.props.type === 'number' && event.target.value) {
      if (!isNumber(event.target.value)) return
    }
    if (this.props.state !== 'disabled') {
      if (this.props.beforeChange) {
        event.target.value = this.props.beforeChange(event.target.value)
      }
      this.props.onChange(event)
    }
  }

  private handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (this.props.state !== 'disabled' && this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  private handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (this.props.state !== 'disabled' && this.props.onKeyDown) {
      this.props.onKeyDown(event)
    }
  }

  private get typeSpecificProps(): TypeSpecificProps {
    if (this.props.type === 'number') {
      return {
        type: 'text',
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }
    }
    return { type: this.props.type }
  }

  public render(): ReactNode {
    return (
      <Styled.TextInputWrapper>
        <Styled.TextInput
          id={this.props.id}
          name={this.props.name || this.props.id}
          value={this.props.value}
          placeholder={this.props.placeholder}
          required={this.props.required}
          ref={this.props.domRef}
          autoComplete={this.props.autoComplete}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onClick={this.props.onClick}
          aria-disabled={this.props.state === 'disabled'}
          aria-invalid={this.props.state === 'error'}
          aria-describedby={this.props.describedBy}
          minLength={this.props.minLength}
          maxLength={this.props.maxLength}
          onKeyDown={this.handleOnKeyDown}
          state={this.props.state}
          readOnly={this.props.state === 'disabled'}
          spellCheck={this.props.spellcheck}
          pattern={this.props.pattern}
          {...this.typeSpecificProps}
          {...this.props.dataAttributes}
        />
        <If condition={this.props.infieldIcon}>
          <Styled.IconWrapper>
            <Icon name={this.props.infieldIcon} group='system' size={3} />
          </Styled.IconWrapper>
        </If>
      </Styled.TextInputWrapper>
    )
  }
}
