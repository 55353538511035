import React, { ReactNode } from 'react'
import { withTheme } from 'styled-components'
import { createPortal } from 'react-dom'

import Animate from '@vfuk/core-animate'
import Overlay from '@vfuk/core-overlay'
import capitalizeFirstCharacter from '@vfuk/core-helpers/dist/capitalizeFirstCharacter'

import { AnimationTypes } from '@vfuk/core-animate/dist/animations/animations.types'

import * as Styled from './styles/FlyoutRenderer.style'

import { FlyoutRendererProps } from './FlyoutRenderer.types'

import localTheme from './themes/FlyoutRenderer.theme'

import Renderer from '../Renderer'

import setInitialFocus from '../utils/setInitialFocus'

import { RendererState } from '../Renderer.types'
import { OverlayCloseSource } from '../constants/constants'

import CloseButton from '../components/CloseButton'

export class FlyoutRenderer extends Renderer<FlyoutRendererProps, RendererState> {
  public static defaultProps: Partial<FlyoutRendererProps> = {
    appearance: 'primary',
    side: 'right',
    overlayBlur: false,
  }

  public render(): ReactNode {
    return createPortal(
      <Styled.FlyoutRenderer zIndex={this.props.zIndex}>
        <Animate
          show={this.state.show}
          enter={{
            animations: [`slideFrom${capitalizeFirstCharacter(this.props.side!)}` as AnimationTypes],
            duration: 200,
            delay: 200,
            onDone: (): void => setInitialFocus(this.props.initialFocusId),
          }}
          exit={{
            animations: [`slideTo${capitalizeFirstCharacter(this.props.side!)}` as AnimationTypes],
            duration: 200,
            onDone: this.onDoneCallback,
          }}
        >
          <Styled.FlyoutRendererContent zIndex={this.props.zIndex} side={this.props.side!} appearance={this.props.appearance!}>
            <Styled.FocusLock shards={this.props.focusEnabledRefs}>
              <CloseButton
                srName={this.props.srName}
                onClick={this.getOnCloseHandler(OverlayCloseSource.FLYOUT_CROSS_CLICK)}
                inverse={localTheme(this.props.theme!).appearance[this.props.appearance!].inverseIcon}
              />
              {this.props.children}
            </Styled.FocusLock>
          </Styled.FlyoutRendererContent>
        </Animate>
        <Animate
          show={this.state.show}
          enter={{
            animations: ['fadeIn'],
            duration: 400,
          }}
          exit={{
            animations: ['fadeOut'],
            duration: 200,
          }}
        >
          <Overlay
            show
            onClick={this.getOnCloseHandler(OverlayCloseSource.FLYOUT_OUTSIDE_CLICK)}
            position='fixed'
            zIndex={0}
            blur={this.props.overlayBlur}
          />
        </Animate>
      </Styled.FlyoutRenderer>,
      document.body,
    )
  }
}

export default withTheme(FlyoutRenderer)
