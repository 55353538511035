import React, { PureComponent, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'

import * as Styled from './styles/CarouselDotPager.style'
import { CarouselDotPagerProps } from './CarouselDotPager.types'

import { componentName } from '../../locales'

export class CarouselDotPager extends PureComponent<CarouselDotPagerProps> {
  public render(): ReactNode {
    return (
      <Styled.CarouselDotPagerWrapper
        active={this.props.active}
        appearance={this.props.appearance || 'primary'}
        inverse={this.props.inverse}
        onClick={(): void => this.props.onClick(this.props.index)}
      >
        <Styled.CarouselDotPager>
          <Styled.SrText>
            {this.props.t!('ItemCountOfTotalCount', { index: this.props.index + 1, pagerCount: this.props.pagerCount })}
            <If condition={this.props.active}>({this.props.t!('Currently Active')})</If>
          </Styled.SrText>
        </Styled.CarouselDotPager>
      </Styled.CarouselDotPagerWrapper>
    )
  }
}

export default withTranslation(componentName)(CarouselDotPager)
