import { ReactNodeArray } from 'react'

import { GoToSlide, Trigger, AnalyticsPayload, FunctionalCarouselSettings } from '../../FunctionalCarousel.types'

export default class SliderControls {
  component: FunctionalCarouselSettings

  constructor(component: FunctionalCarouselSettings) {
    this.component = component
  }

  private get slides(): ReactNodeArray {
    if (this.component.sliderRef && Array.isArray(this.component.sliderRef.props.children)) {
      return this.component.sliderRef.props.children
    }
    return []
  }

  private goToSlide = ({ slideIndex, trigger }: GoToSlide): void => {
    if (this.component.state.transitioning || !this.component.sliderRef) return
    if (this.component.props.pager?.onClick && trigger === 'pager') {
      this.component.props.pager.onClick(this.getAnalyticsPayload(trigger, slideIndex))
    }
    if (this.component.props.arrows?.onClick && (trigger === 'nextArrow' || trigger === 'previousArrow')) {
      this.component.props.arrows.onClick(this.getAnalyticsPayload(trigger, slideIndex))
    }
    this.component.sliderRef.slickGoTo(slideIndex, false)
  }

  private getAnalyticsPayload(trigger: Trigger, slideIndex: number): AnalyticsPayload {
    return {
      currentSlide: slideIndex,
      id: this.component.props.id!,
      numberOfSlides: this.slides.length,
      trigger,
    }
  }

  public goToPagerNumber = (pagerNumber: number): void => {
    const slideToGoTo = this.component.state.slidesToScroll * pagerNumber
    this.goToSlide({
      slideIndex: slideToGoTo,
      trigger: 'pager',
    })
  }

  public goToNextSlide = (): void => {
    this.goToSlide({
      slideIndex: this.component.state.currentSlide + this.component.state.slidesToScroll,
      trigger: 'nextArrow',
    })
  }

  public goToPreviousSlide = (): void => {
    this.goToSlide({
      slideIndex: this.component.state.currentSlide - this.component.state.slidesToScroll,
      trigger: 'previousArrow',
    })
  }

  public play = (): void => {
    if (!this.component.sliderRef) return
    this.component.sliderRef.slickPlay()
    this.component.setState({
      playing: true,
    })
  }

  public pause = (): void => {
    if (!this.component.sliderRef) return
    this.component.sliderRef.slickPause()
    this.component.setState({
      playing: false,
    })
  }
}
