import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { typography } from '@vfuk/core-mixins'

import { StyledHeadingProps, LevelToSize } from './Heading.style.types'

import localThemes from '../themes/Heading.theme'

const levelToSize: LevelToSize = {
  1: 5,
  2: 4,
  3: 3,
  4: 2,
  5: 1,
}

export const Heading = styled.h2((props: StyledHeadingProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    color: ${localTheme.appearance[props.appearance!].color};
    ${typography.heading(props.size || levelToSize[props.level!], !props.noMargin)}

    ${props.weight &&
    `
      font-weight: ${props.theme.fontWeight[props.weight]};
    `}

    ${props.justify &&
    `
      text-align: ${props.justify};
    `}

    ${props.inverse &&
    `
      color: ${localTheme.appearance[props.appearance!].inverse.color};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  `
})
