import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import iconSizes from './mixins/iconSizes'

import { StyledIconProps } from './Icon.style.types'

export const Icon = styled.span<StyledIconProps>((props: StyledIconProps): FlattenSimpleInterpolation => {
  const localTheme = props.theme.getIconProperties(props.group!)
  const fillOrStroke = (value: string | undefined): string => {
    if (localTheme?.colorAppliesTo === 'stroke') {
      return `stroke: ${value};`
    }
    return `>* { fill: ${value}; }`
  }

  return css`
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    span {
      display: inline-flex;
    }

    svg {
      ${iconSizes(props.size, props.isResponsive, props.group)}

      ${fillOrStroke(localTheme?.appearance?.[props.appearance!]?.color)}

      ${props.inverse && fillOrStroke(localTheme?.appearance?.[props.appearance!]?.inverse.color)}

      ${props.state && fillOrStroke(localTheme?.state?.[props.state].color)}
    }
  `
})

export const ErrorPinkBox = styled.span((): FlattenSimpleInterpolation => {
  const placeholder: string = '#ff00ff'
  return css`
    display: inline-flex;
    vertical-align: text-bottom;
    width: 30px;
    height: 30px;
    background-color: ${placeholder};
  `
})

export const NumberBadgeContainer = styled.span((): FlattenSimpleInterpolation => {
  return css`
    position: absolute;
    right: -8px;
    top: -7px;
  `
})
