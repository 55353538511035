import React, { FC } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'
import LoadingSpinner from '@vfuk/core-loading-spinner'

import * as Styled from './styles/Button.style'

import { ButtonProps } from './Button.types'

import localThemes from './themes/Button.theme'

export const Button: FC<ButtonProps> = ({
  appearance = 'primary',
  theme,
  width = 'auto',
  loading = false,
  htmlAttributes,
  inverse = false,
  state,
  href,
  id,
  text,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  srText,
  customRouterProps,
}) => {
  const localThemeAppearance = localThemes(theme!).appearance[appearance!]
  const isDisabledOrLoading = state === 'disabled' || loading!

  return (
    <Styled.Button
      appearance={appearance!}
      dataAttributes={htmlAttributes?.dataAttributes}
      disabled={isDisabledOrLoading}
      href={!isDisabledOrLoading ? href : undefined}
      id={id}
      inverse={inverse!}
      label={srText || text}
      loading={loading!}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      state={state!}
      target={htmlAttributes?.target}
      type={htmlAttributes?.type}
      width={width!}
      customRouterProps={customRouterProps}
    >
      <Choose>
        <When condition={loading}>
          <Styled.LoadingIcon>
            <LoadingSpinner
              appearance={inverse ? localThemeAppearance.inverse.loadingSpinnerAppearance : localThemeAppearance.loadingSpinnerAppearance}
              inverse={inverse ? localThemeAppearance.inverse.inverseIcon : localThemeAppearance.inverseIcon}
              size={2}
            />
          </Styled.LoadingIcon>
          <Styled.HiddenText>{text}</Styled.HiddenText>
        </When>
        <When condition={inverse && state === 'selected'}>
          <Icon
            appearance={localThemeAppearance.inverse.state[state!].iconAppearance}
            inverse={localThemeAppearance.inverse.state[state!].inverseIcon}
            name='tick'
            group='system'
            size={3}
            isResponsive={false}
          />
          {text}
        </When>
        <When condition={state === 'selected'}>
          <Icon
            appearance={localThemeAppearance.state[state!].iconAppearance}
            inverse={localThemeAppearance.state[state!].inverseIcon}
            name='tick'
            group='system'
            size={3}
            isResponsive={false}
          />
          {text}
        </When>
        <Otherwise>{text}</Otherwise>
      </Choose>
    </Styled.Button>
  )
}

export default withTheme(Button)
